/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AgencyActivityType {
  CREATE_AGENCY = "CREATE_AGENCY",
  CREATE_CHAT_WIDGET = "CREATE_CHAT_WIDGET",
  CREATE_ROULETTE_PERIOD = "CREATE_ROULETTE_PERIOD",
  CREATE_SALES_GROUP = "CREATE_SALES_GROUP",
  UPDATE_AGENCY_DATA = "UPDATE_AGENCY_DATA",
  UPDATE_CHAT_WIDGET = "UPDATE_CHAT_WIDGET",
  UPDATE_ROULETTE_PERIOD = "UPDATE_ROULETTE_PERIOD",
  UPDATE_SALES_GROUP = "UPDATE_SALES_GROUP",
}

export enum AgencyChildFileUploadType {
  OTHER = "OTHER",
  PRIVACY_POLICY = "PRIVACY_POLICY",
  TERMS_OF_USE = "TERMS_OF_USE",
}

export enum AgencyRoulettesOrderByInput {
  active_ASC = "active_ASC",
  active_DESC = "active_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
}

export enum BilletType {
  BOLETO_SIMPLES = "BOLETO_SIMPLES",
  IMOVIEW = "IMOVIEW",
  INFO_LINE = "INFO_LINE",
  INGAIA = "INGAIA",
  KUROLE = "KUROLE",
  OMIE = "OMIE",
}

export enum ChangeChatCustomerDataEnum {
  ALL = "ALL",
  EMAIL = "EMAIL",
  NAME = "NAME",
  PHONE = "PHONE",
}

export enum ChatClientGroupUpdatedEventOperation {
  UPDATE = "UPDATE",
}

export enum ChatClientOrderBy {
  AGENCY_ASC = "AGENCY_ASC",
  AGENCY_DESC = "AGENCY_DESC",
  CREATION_DATE_ASC = "CREATION_DATE_ASC",
  CREATION_DATE_DESC = "CREATION_DATE_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  SALESMAN_ASC = "SALESMAN_ASC",
  SALESMAN_DESC = "SALESMAN_DESC",
  UPDATE_DATE_ASC = "UPDATE_DATE_ASC",
  UPDATE_DATE_DESC = "UPDATE_DATE_DESC",
}

export enum ChatClientRealEstateFeedbackReaction {
  DONT_LIKE = "DONT_LIKE",
  LIKE = "LIKE",
  LOVE = "LOVE",
}

export enum ChatClientTagType {
  ADMINISTRATIVE = "ADMINISTRATIVE",
  ANGARIATION = "ANGARIATION",
  APOLAR_AGENCY_WEBSITE = "APOLAR_AGENCY_WEBSITE",
  APOLAR_WEBSITE = "APOLAR_WEBSITE",
  AUX_1 = "AUX_1",
  AUX_10 = "AUX_10",
  AUX_11 = "AUX_11",
  AUX_12 = "AUX_12",
  AUX_13 = "AUX_13",
  AUX_14 = "AUX_14",
  AUX_15 = "AUX_15",
  AUX_16 = "AUX_16",
  AUX_17 = "AUX_17",
  AUX_18 = "AUX_18",
  AUX_19 = "AUX_19",
  AUX_2 = "AUX_2",
  AUX_20 = "AUX_20",
  AUX_21 = "AUX_21",
  AUX_22 = "AUX_22",
  AUX_23 = "AUX_23",
  AUX_24 = "AUX_24",
  AUX_25 = "AUX_25",
  AUX_26 = "AUX_26",
  AUX_27 = "AUX_27",
  AUX_28 = "AUX_28",
  AUX_29 = "AUX_29",
  AUX_3 = "AUX_3",
  AUX_30 = "AUX_30",
  AUX_31 = "AUX_31",
  AUX_32 = "AUX_32",
  AUX_33 = "AUX_33",
  AUX_34 = "AUX_34",
  AUX_35 = "AUX_35",
  AUX_36 = "AUX_36",
  AUX_37 = "AUX_37",
  AUX_38 = "AUX_38",
  AUX_39 = "AUX_39",
  AUX_4 = "AUX_4",
  AUX_40 = "AUX_40",
  AUX_41 = "AUX_41",
  AUX_42 = "AUX_42",
  AUX_43 = "AUX_43",
  AUX_44 = "AUX_44",
  AUX_45 = "AUX_45",
  AUX_46 = "AUX_46",
  AUX_47 = "AUX_47",
  AUX_48 = "AUX_48",
  AUX_49 = "AUX_49",
  AUX_5 = "AUX_5",
  AUX_50 = "AUX_50",
  AUX_51 = "AUX_51",
  AUX_52 = "AUX_52",
  AUX_53 = "AUX_53",
  AUX_54 = "AUX_54",
  AUX_55 = "AUX_55",
  AUX_56 = "AUX_56",
  AUX_57 = "AUX_57",
  AUX_58 = "AUX_58",
  AUX_59 = "AUX_59",
  AUX_6 = "AUX_6",
  AUX_60 = "AUX_60",
  AUX_61 = "AUX_61",
  AUX_62 = "AUX_62",
  AUX_63 = "AUX_63",
  AUX_64 = "AUX_64",
  AUX_65 = "AUX_65",
  AUX_66 = "AUX_66",
  AUX_67 = "AUX_67",
  AUX_68 = "AUX_68",
  AUX_69 = "AUX_69",
  AUX_7 = "AUX_7",
  AUX_70 = "AUX_70",
  AUX_71 = "AUX_71",
  AUX_72 = "AUX_72",
  AUX_73 = "AUX_73",
  AUX_74 = "AUX_74",
  AUX_75 = "AUX_75",
  AUX_76 = "AUX_76",
  AUX_77 = "AUX_77",
  AUX_78 = "AUX_78",
  AUX_79 = "AUX_79",
  AUX_8 = "AUX_8",
  AUX_80 = "AUX_80",
  AUX_81 = "AUX_81",
  AUX_82 = "AUX_82",
  AUX_83 = "AUX_83",
  AUX_84 = "AUX_84",
  AUX_85 = "AUX_85",
  AUX_86 = "AUX_86",
  AUX_87 = "AUX_87",
  AUX_88 = "AUX_88",
  AUX_89 = "AUX_89",
  AUX_9 = "AUX_9",
  AUX_90 = "AUX_90",
  AUX_91 = "AUX_91",
  AUX_92 = "AUX_92",
  AUX_93 = "AUX_93",
  AUX_94 = "AUX_94",
  AUX_95 = "AUX_95",
  AUX_96 = "AUX_96",
  AUX_97 = "AUX_97",
  AUX_98 = "AUX_98",
  AUX_99 = "AUX_99",
  BILLET = "BILLET",
  BUY = "BUY",
  C3DWEB = "C3DWEB",
  CHATBOT = "CHATBOT",
  CHAVES_NA_MAO = "CHAVES_NA_MAO",
  CLIQUEI_MUDEI = "CLIQUEI_MUDEI",
  COMPLAIN = "COMPLAIN",
  COMPLIMENT = "COMPLIMENT",
  COTA_1 = "COTA_1",
  COTA_2 = "COTA_2",
  COTA_3 = "COTA_3",
  COTA_4 = "COTA_4",
  CREDITO_REAL = "CREDITO_REAL",
  CRM = "CRM",
  CRM_ERROR = "CRM_ERROR",
  DASHBOARD = "DASHBOARD",
  DEFAULTER = "DEFAULTER",
  DOCUMENTING_QUESTION = "DOCUMENTING_QUESTION",
  EMAIL = "EMAIL",
  FACEBOOK = "FACEBOOK",
  FINANCIAL = "FINANCIAL",
  FINANCIAL_SIMULATION = "FINANCIAL_SIMULATION",
  FORM_SITE_TANTUS = "FORM_SITE_TANTUS",
  HAS_CLONES = "HAS_CLONES",
  HAS_FILES = "HAS_FILES",
  HUMAN_RESOURCES = "HUMAN_RESOURCES",
  IMOVEL_WEB = "IMOVEL_WEB",
  IMO_CASA = "IMO_CASA",
  IS_CLONE = "IS_CLONE",
  LEADS_4_SALES = "LEADS_4_SALES",
  MAINTENANCE = "MAINTENANCE",
  MARKETING = "MARKETING",
  MERCADO_LIVRE = "MERCADO_LIVRE",
  MESSAGE = "MESSAGE",
  NAME_CALLING = "NAME_CALLING",
  NOTIFICATION = "NOTIFICATION",
  OBSERVATION = "OBSERVATION",
  OLX = "OLX",
  OPPORTUNITY = "OPPORTUNITY",
  PARTNERSHIP = "PARTNERSHIP",
  PHONE = "PHONE",
  PURCHASES = "PURCHASES",
  RD_STATION = "RD_STATION",
  REAL_ESTATE_REFERENCE = "REAL_ESTATE_REFERENCE",
  RENT = "RENT",
  SEARCH = "SEARCH",
  SEASON_RENTAL = "SEASON_RENTAL",
  VISIT = "VISIT",
  VIVA_REAL = "VIVA_REAL",
  WHATSAPP = "WHATSAPP",
  WITHOUT_SALESMAN = "WITHOUT_SALESMAN",
  ZAP = "ZAP",
}

export enum ChatClientTalkingTo {
  BOT = "BOT",
  HUMAN = "HUMAN",
}

export enum ChatClientUpdatedEventOperation {
  UPDATE = "UPDATE",
}

export enum ChatCountType {
  chat = "chat",
  client = "client",
}

export enum ChatCustomerRegistrationChannel {
  API = "API",
  APOLAR_AGENCY_WEBSITE = "APOLAR_AGENCY_WEBSITE",
  APOLAR_WEBSITE = "APOLAR_WEBSITE",
  C3DWEB = "C3DWEB",
  CHAT = "CHAT",
  CHAVES_NA_MAO = "CHAVES_NA_MAO",
  CLIENT_IMPORTER = "CLIENT_IMPORTER",
  CLIQUEI_MUDEI = "CLIQUEI_MUDEI",
  CREDITO_REAL = "CREDITO_REAL",
  EXTERNAL_EMAIL = "EXTERNAL_EMAIL",
  FACEBOOK = "FACEBOOK",
  FORM_SITE_TANTUS = "FORM_SITE_TANTUS",
  IMOVEL_WEB = "IMOVEL_WEB",
  IMO_CASA = "IMO_CASA",
  LEADS_4_SALES = "LEADS_4_SALES",
  MERCADO_LIVRE = "MERCADO_LIVRE",
  OLX = "OLX",
  RD_STATION = "RD_STATION",
  VIVA_REAL = "VIVA_REAL",
  WHATSAPP = "WHATSAPP",
  ZAP = "ZAP",
}

export enum ChatFilter {
  CHAT_LOADED = "CHAT_LOADED",
  CHAT_OPENED = "CHAT_OPENED",
  DASHBOARD_GRID_SELECTION = "DASHBOARD_GRID_SELECTION",
  EVALUATED = "EVALUATED",
  HAS_FILES = "HAS_FILES",
  HAS_INVALID_INPUT = "HAS_INVALID_INPUT",
  INFORMED_DATA = "INFORMED_DATA",
  INFORMED_DATA_NOT = "INFORMED_DATA_NOT",
  INTERACTED = "INTERACTED",
  IS_ADMINISTRATIVE = "IS_ADMINISTRATIVE",
  IS_BUY = "IS_BUY",
  IS_CLONE = "IS_CLONE",
  IS_FINANCIAL = "IS_FINANCIAL",
  IS_RENT = "IS_RENT",
  NOT_ADMINISTRATIVE = "NOT_ADMINISTRATIVE",
  NOT_BUY = "NOT_BUY",
  NOT_RENT = "NOT_RENT",
  PAGE_VISITED = "PAGE_VISITED",
  SCHEDULED_VISIT = "SCHEDULED_VISIT",
  SEARCHED = "SEARCHED",
}

export enum ChatFlowCharacterGender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  UNDEFINED = "UNDEFINED",
}

export enum ChatMessageOrigin {
  BOT = "BOT",
  USER = "USER",
}

export enum ChatMessageType {
  IMAGE = "IMAGE",
  IMAGES = "IMAGES",
  REAL_ESTATE = "REAL_ESTATE",
  START_NEW_CHAT = "START_NEW_CHAT",
  TEXT = "TEXT",
}

export enum ChatSubFlowName {
  ALREADY_CLIENT_CHAT_FLOW = "ALREADY_CLIENT_CHAT_FLOW",
  ANGARIATION_CHAT_FLOW = "ANGARIATION_CHAT_FLOW",
  APOLAR_BILLET_CHAT_FLOW = "APOLAR_BILLET_CHAT_FLOW",
  APOLAR_EXTRACT_LIST_CHAT_FLOW = "APOLAR_EXTRACT_LIST_CHAT_FLOW",
  APOLAR_REVENUE_CHAT_FLOW = "APOLAR_REVENUE_CHAT_FLOW",
  AUX_EIGHTEEN_CHAT_FLOW = "AUX_EIGHTEEN_CHAT_FLOW",
  AUX_EIGHT_CHAT_FLOW = "AUX_EIGHT_CHAT_FLOW",
  AUX_ELEVEN_CHAT_FLOW = "AUX_ELEVEN_CHAT_FLOW",
  AUX_FIFTEEN_CHAT_FLOW = "AUX_FIFTEEN_CHAT_FLOW",
  AUX_FIVE_CHAT_FLOW = "AUX_FIVE_CHAT_FLOW",
  AUX_FOURTEEN_CHAT_FLOW = "AUX_FOURTEEN_CHAT_FLOW",
  AUX_FOUR_CHAT_FLOW = "AUX_FOUR_CHAT_FLOW",
  AUX_NINETEEN_CHAT_FLOW = "AUX_NINETEEN_CHAT_FLOW",
  AUX_NINE_CHAT_FLOW = "AUX_NINE_CHAT_FLOW",
  AUX_ONE_CHAT_FLOW = "AUX_ONE_CHAT_FLOW",
  AUX_SEVENTEEN_CHAT_FLOW = "AUX_SEVENTEEN_CHAT_FLOW",
  AUX_SEVEN_CHAT_FLOW = "AUX_SEVEN_CHAT_FLOW",
  AUX_SIXTEEN_CHAT_FLOW = "AUX_SIXTEEN_CHAT_FLOW",
  AUX_SIX_CHAT_FLOW = "AUX_SIX_CHAT_FLOW",
  AUX_TEN_CHAT_FLOW = "AUX_TEN_CHAT_FLOW",
  AUX_THIRTEEN_CHAT_FLOW = "AUX_THIRTEEN_CHAT_FLOW",
  AUX_THIRTY_CHAT_FLOW = "AUX_THIRTY_CHAT_FLOW",
  AUX_THREE_CHAT_FLOW = "AUX_THREE_CHAT_FLOW",
  AUX_TWELVE_CHAT_FLOW = "AUX_TWELVE_CHAT_FLOW",
  AUX_TWENTY_CHAT_FLOW = "AUX_TWENTY_CHAT_FLOW",
  AUX_TWENTY_EIGHT_CHAT_FLOW = "AUX_TWENTY_EIGHT_CHAT_FLOW",
  AUX_TWENTY_FIVE_CHAT_FLOW = "AUX_TWENTY_FIVE_CHAT_FLOW",
  AUX_TWENTY_FOUR_CHAT_FLOW = "AUX_TWENTY_FOUR_CHAT_FLOW",
  AUX_TWENTY_NINE_CHAT_FLOW = "AUX_TWENTY_NINE_CHAT_FLOW",
  AUX_TWENTY_ONE_CHAT_FLOW = "AUX_TWENTY_ONE_CHAT_FLOW",
  AUX_TWENTY_SEVEN_CHAT_FLOW = "AUX_TWENTY_SEVEN_CHAT_FLOW",
  AUX_TWENTY_SIX_CHAT_FLOW = "AUX_TWENTY_SIX_CHAT_FLOW",
  AUX_TWENTY_THREE_CHAT_FLOW = "AUX_TWENTY_THREE_CHAT_FLOW",
  AUX_TWENTY_TWO_CHAT_FLOW = "AUX_TWENTY_TWO_CHAT_FLOW",
  AUX_TWO_CHAT_FLOW = "AUX_TWO_CHAT_FLOW",
  BAND_VALE_CUSTOMER_DATA = "BAND_VALE_CUSTOMER_DATA",
  BG_SECONDARY_MENU_CHAT_FLOW = "BG_SECONDARY_MENU_CHAT_FLOW",
  CORPORATIVE_REAL_ESTATE_QUESTIONS_CHAT_FLOW = "CORPORATIVE_REAL_ESTATE_QUESTIONS_CHAT_FLOW",
  CUSTOMER_DATA_CHAT_FLOW = "CUSTOMER_DATA_CHAT_FLOW",
  ENDING_CHAT_FLOW = "ENDING_CHAT_FLOW",
  FINANCIAL_SIMULATION_FLOW = "FINANCIAL_SIMULATION_FLOW",
  FINANCING_QUESTIONS_CHAT_FLOW = "FINANCING_QUESTIONS_CHAT_FLOW",
  FINANCING_QUESTIONS_KEY_ON_CHAT_FLOW = "FINANCING_QUESTIONS_KEY_ON_CHAT_FLOW",
  FINANCING_QUESTIONS_MINIMAL_CHAT_FLOW = "FINANCING_QUESTIONS_MINIMAL_CHAT_FLOW",
  FINANCING_QUESTIONS_TOFANO_CHAT_FLOW = "FINANCING_QUESTIONS_TOFANO_CHAT_FLOW",
  FUNNEL_QUERY_CHAT_FLOW = "FUNNEL_QUERY_CHAT_FLOW",
  FUNNEL_QUERY_CLIENTS_CHAT_FLOW = "FUNNEL_QUERY_CLIENTS_CHAT_FLOW",
  GENERIC_BILLET_CHAT_FLOW = "GENERIC_BILLET_CHAT_FLOW",
  GENERIC_CONTRACT_CHAT_FLOW = "GENERIC_CONTRACT_CHAT_FLOW",
  GENERIC_OWNER_EXTRACT_CHAT_FLOW = "GENERIC_OWNER_EXTRACT_CHAT_FLOW",
  HUMAN_RESOURCES_FLOW = "HUMAN_RESOURCES_FLOW",
  INPUT_EIGHT_CHAT_FLOW = "INPUT_EIGHT_CHAT_FLOW",
  INPUT_FIVE_CHAT_FLOW = "INPUT_FIVE_CHAT_FLOW",
  INPUT_FOUR_CHAT_FLOW = "INPUT_FOUR_CHAT_FLOW",
  INPUT_NINE_CHAT_FLOW = "INPUT_NINE_CHAT_FLOW",
  INPUT_ONE_CHAT_FLOW = "INPUT_ONE_CHAT_FLOW",
  INPUT_SEVEN_CHAT_FLOW = "INPUT_SEVEN_CHAT_FLOW",
  INPUT_SIX_CHAT_FLOW = "INPUT_SIX_CHAT_FLOW",
  INPUT_TEN_CHAT_FLOW = "INPUT_TEN_CHAT_FLOW",
  INPUT_THREE_CHAT_FLOW = "INPUT_THREE_CHAT_FLOW",
  INPUT_TWO_CHAT_FLOW = "INPUT_TWO_CHAT_FLOW",
  KUROLE_OCCURRENCE = "KUROLE_OCCURRENCE",
  REAL_ESTATE_NO_SEARCH_CHAT_FLOW = "REAL_ESTATE_NO_SEARCH_CHAT_FLOW",
  REAL_ESTATE_QUESTIONS_CHAT_FLOW = "REAL_ESTATE_QUESTIONS_CHAT_FLOW",
  REAL_ESTATE_SHOW_CHAT_FLOW = "REAL_ESTATE_SHOW_CHAT_FLOW",
  SPEAK_ABOUT_CONDO_CHAT_FLOW = "SPEAK_ABOUT_CONDO_CHAT_FLOW",
  SPEAK_WITH_SALESMAN_CHAT_FLOW = "SPEAK_WITH_SALESMAN_CHAT_FLOW",
  SPEAK_WITH_SALESMAN_REDUX_CHAT_FLOW = "SPEAK_WITH_SALESMAN_REDUX_CHAT_FLOW",
  START = "START",
  VISIT_QUESTIONS_CHAT_FLOW = "VISIT_QUESTIONS_CHAT_FLOW",
}

export enum ChatWidgetAnalyticsType {
  GOOGLE_ANALYTICS = "GOOGLE_ANALYTICS",
  GOOGLE_TAG_MANAGER = "GOOGLE_TAG_MANAGER",
}

export enum ChatWidgetChannel {
  FACEBOOK = "FACEBOOK",
  SITE = "SITE",
  SLACK = "SLACK",
  TELEGRAM = "TELEGRAM",
  WEBCHAT = "WEBCHAT",
  WHATSAPP = "WHATSAPP",
}

export enum ChatWidgetScheduleAction {
  HIDE_WIDGET = "HIDE_WIDGET",
  SHOW_MESSAGE = "SHOW_MESSAGE",
}

export enum ChatWidgetScheduleWeekday {
  FRIDAY = "FRIDAY",
  MONDAY = "MONDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
  THURSDAY = "THURSDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
}

export enum CivilState {
  DIVORCED = "DIVORCED",
  MARRIED = "MARRIED",
  SEPARATED = "SEPARATED",
  SINGLE = "SINGLE",
  UNMARRIED = "UNMARRIED",
  WIDOWED = "WIDOWED",
}

export enum ClientActivityType {
  ADDED_FROM_API = "ADDED_FROM_API",
  ADDED_FROM_CLIENT_IMPORTER = "ADDED_FROM_CLIENT_IMPORTER",
  ADDED_FROM_EMAIL = "ADDED_FROM_EMAIL",
  ADDED_FROM_WEBHOOK = "ADDED_FROM_WEBHOOK",
  ADDED_FROM_WHATSAPP = "ADDED_FROM_WHATSAPP",
  CHANGED_AGENCY = "CHANGED_AGENCY",
  CHANGED_CLIENT_DATA = "CHANGED_CLIENT_DATA",
  CHANGED_CLIENT_FUNNEL_STEP = "CHANGED_CLIENT_FUNNEL_STEP",
  CLONE_CREATED = "CLONE_CREATED",
  CLONE_NOT_CREATED = "CLONE_NOT_CREATED",
  CLONE_SYNCED = "CLONE_SYNCED",
  CRM_FUNNEL_STEP_UPDATED = "CRM_FUNNEL_STEP_UPDATED",
  ENDED_CHAT = "ENDED_CHAT",
  INSERTED_IN_CRM = "INSERTED_IN_CRM",
  MANUAL_ACTION = "MANUAL_ACTION",
  RESTORED = "RESTORED",
  SENT_OBSERVATION_MESSAGE = "SENT_OBSERVATION_MESSAGE",
  SENT_WHATSAPP_MESSAGE = "SENT_WHATSAPP_MESSAGE",
  SET_AS_DELETED = "SET_AS_DELETED",
  SHARED_WITH_USERS = "SHARED_WITH_USERS",
  UNSHARED_WITH_USERS = "UNSHARED_WITH_USERS",
  UPDATE_TAGS = "UPDATE_TAGS",
}

export enum ClientFileUploadType {
  CONTRACT = "CONTRACT",
  OTHER = "OTHER",
  PERSONAL_DOCUMENT = "PERSONAL_DOCUMENT",
}

export enum ClientSearchType {
  ALL = "ALL",
  ALL_EXACT = "ALL_EXACT",
  CPF = "CPF",
  EMAIL = "EMAIL",
  NAME = "NAME",
  PHONE = "PHONE",
}

export enum ClientsImporterType {
  VOUCH = "VOUCH",
}

export enum CrcStepShowEnum {
  ALREADY_CLIENT = "ALREADY_CLIENT",
  AUX_EIGHT = "AUX_EIGHT",
  AUX_EIGHTEEN = "AUX_EIGHTEEN",
  AUX_ELEVEN = "AUX_ELEVEN",
  AUX_FIFTEEN = "AUX_FIFTEEN",
  AUX_FIVE = "AUX_FIVE",
  AUX_FOUR = "AUX_FOUR",
  AUX_FOURTEEN = "AUX_FOURTEEN",
  AUX_NINE = "AUX_NINE",
  AUX_NINETEEN = "AUX_NINETEEN",
  AUX_ONE = "AUX_ONE",
  AUX_SEVEN = "AUX_SEVEN",
  AUX_SEVENTEEN = "AUX_SEVENTEEN",
  AUX_SIX = "AUX_SIX",
  AUX_SIXTEEN = "AUX_SIXTEEN",
  AUX_TEN = "AUX_TEN",
  AUX_THIRTEEN = "AUX_THIRTEEN",
  AUX_THIRTY = "AUX_THIRTY",
  AUX_THREE = "AUX_THREE",
  AUX_TWELVE = "AUX_TWELVE",
  AUX_TWENTY = "AUX_TWENTY",
  AUX_TWENTY_EIGHT = "AUX_TWENTY_EIGHT",
  AUX_TWENTY_FIVE = "AUX_TWENTY_FIVE",
  AUX_TWENTY_FOUR = "AUX_TWENTY_FOUR",
  AUX_TWENTY_NINE = "AUX_TWENTY_NINE",
  AUX_TWENTY_ONE = "AUX_TWENTY_ONE",
  AUX_TWENTY_SEVEN = "AUX_TWENTY_SEVEN",
  AUX_TWENTY_SIX = "AUX_TWENTY_SIX",
  AUX_TWENTY_THREE = "AUX_TWENTY_THREE",
  AUX_TWENTY_TWO = "AUX_TWENTY_TWO",
  AUX_TWO = "AUX_TWO",
  BAND_VALE_CUSTOMER_DATA = "BAND_VALE_CUSTOMER_DATA",
  INPUT_EIGHT = "INPUT_EIGHT",
  INPUT_FIVE = "INPUT_FIVE",
  INPUT_FOUR = "INPUT_FOUR",
  INPUT_NINE = "INPUT_NINE",
  INPUT_ONE = "INPUT_ONE",
  INPUT_SEVEN = "INPUT_SEVEN",
  INPUT_SIX = "INPUT_SIX",
  INPUT_TEN = "INPUT_TEN",
  INPUT_THREE = "INPUT_THREE",
  INPUT_TWO = "INPUT_TWO",
  REAL_ESTATE = "REAL_ESTATE",
  START = "START",
}

export enum CrmSendMethod {
  AUTOMATICALLY = "AUTOMATICALLY",
  MANUAL = "MANUAL",
}

export enum CrmType {
  AGENDOR = "AGENDOR",
  APOLAR = "APOLAR",
  APRESENTA_ME = "APRESENTA_ME",
  ARBO = "ARBO",
  BETA_BIT = "BETA_BIT",
  BITRIX24 = "BITRIX24",
  C2TI_XML = "C2TI_XML",
  CASA_SOFT = "CASA_SOFT",
  CONTACT_2_SALE = "CONTACT_2_SALE",
  CV_LEADS = "CV_LEADS",
  EXACT_SALES = "EXACT_SALES",
  HARRY = "HARRY",
  IMOBEX = "IMOBEX",
  IMOBZI = "IMOBZI",
  IMOVIEW = "IMOVIEW",
  INGAIA = "INGAIA",
  JETIMOBI = "JETIMOBI",
  KUROLE = "KUROLE",
  MOLD_SYSTEM = "MOLD_SYSTEM",
  NIDO = "NIDO",
  RD_STATION = "RD_STATION",
  RD_STATION_MARKETING = "RD_STATION_MARKETING",
  REDE_IMOVEIS = "REDE_IMOVEIS",
  SI9 = "SI9",
  SIGASOFT = "SIGASOFT",
  SIGAVI = "SIGAVI",
  SIMOB = "SIMOB",
  SOHTEC = "SOHTEC",
  SYNGOO = "SYNGOO",
  UNION = "UNION",
  UNISOFT = "UNISOFT",
  UNIVEN = "UNIVEN",
  VISTA = "VISTA",
}

export enum CustomerOrigin {
  ACTION = "ACTION",
  C3DWEB = "C3DWEB",
  CALL = "CALL",
  CASA_MINEIRA = "CASA_MINEIRA",
  CHATBOT = "CHATBOT",
  CHAVES_NA_MAO = "CHAVES_NA_MAO",
  CHAVE_FACIL = "CHAVE_FACIL",
  CONSTRUTORA = "CONSTRUTORA",
  EMAIL = "EMAIL",
  FACEBOOK = "FACEBOOK",
  FORM_SITE_TANTUS = "FORM_SITE_TANTUS",
  IMOVEL_WEB = "IMOVEL_WEB",
  IMO_CASA = "IMO_CASA",
  INDICACAO = "INDICACAO",
  INSTAGRAM = "INSTAGRAM",
  LINKEDIN = "LINKEDIN",
  MERCADO_LIVRE = "MERCADO_LIVRE",
  OLX = "OLX",
  OUTROS = "OUTROS",
  PARCERIA = "PARCERIA",
  PHONE = "PHONE",
  PLACAS = "PLACAS",
  PLANTAO = "PLANTAO",
  RD_STATION = "RD_STATION",
  REDE_INOVA = "REDE_INOVA",
  SALESMAN_PORTFOLIO = "SALESMAN_PORTFOLIO",
  SHOP = "SHOP",
  SITE = "SITE",
  SITE_REDE_UNA = "SITE_REDE_UNA",
  VIVA_REAL = "VIVA_REAL",
  WHATSAPP = "WHATSAPP",
  ZAP = "ZAP",
}

export enum CustomerPreferredChannel {
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  WHATSAPP = "WHATSAPP",
}

export enum DashboardPage {
  AGENCIES_PAGE = "AGENCIES_PAGE",
  ATTENDANT_EVALUATIONS_PAGE = "ATTENDANT_EVALUATIONS_PAGE",
  BOT_EVALUATIONS_PAGE = "BOT_EVALUATIONS_PAGE",
  CHAT_ONLINE_PAGE = "CHAT_ONLINE_PAGE",
  CLIENTS_CHOICES_PAGE = "CLIENTS_CHOICES_PAGE",
  CLIENTS_EMAILS_PAGE = "CLIENTS_EMAILS_PAGE",
  CLIENTS_ESTATISTICS_PAGE = "CLIENTS_ESTATISTICS_PAGE",
  CLIENTS_PAGE = "CLIENTS_PAGE",
  CLIENTS_SEARCH_PAGE = "CLIENTS_SEARCH_PAGE",
  CLIENTS_SUM_PAGE = "CLIENTS_SUM_PAGE",
  CLIENT_GROUP_PAGE = "CLIENT_GROUP_PAGE",
  DELETED_CLIENTS_PAGE = "DELETED_CLIENTS_PAGE",
  ERRORS_OCCURRED_PAGE = "ERRORS_OCCURRED_PAGE",
  EXTERNAL_CHAT_CHANNEL_STATS_PAGE = "EXTERNAL_CHAT_CHANNEL_STATS_PAGE",
  FAIL_CHAT_PAGE = "FAIL_CHAT_PAGE",
  FUNNEL_STEPS_EDIT_PAGE = "FUNNEL_STEPS_EDIT_PAGE",
  FUNNEL_STEPS_PAGE = "FUNNEL_STEPS_PAGE",
  MESSAGES_TO_SALESMAN_PAGE = "MESSAGES_TO_SALESMAN_PAGE",
  PORTIFOLIO_ANALYSIS_PAGE = "PORTIFOLIO_ANALYSIS_PAGE",
  REAL_ESTATE_AGENCIES_DATABASES_PAGE = "REAL_ESTATE_AGENCIES_DATABASES_PAGE",
  REAL_ESTATE_DATA_QUALITY_PAGE = "REAL_ESTATE_DATA_QUALITY_PAGE",
  REAL_ESTATE_RECOMMENDATION_PAGE = "REAL_ESTATE_RECOMMENDATION_PAGE",
  REAL_ESTATE_SEARCH_PAGE = "REAL_ESTATE_SEARCH_PAGE",
  REAL_ESTATE_STATISTICS_PAGE = "REAL_ESTATE_STATISTICS_PAGE",
  REAL_TIME_GRAPHS_PAGE = "REAL_TIME_GRAPHS_PAGE",
  REGION_EDIT_PAGE = "REGION_EDIT_PAGE",
  ROULETTE_PAGE = "ROULETTE_PAGE",
  SCHEDULE_MESSAGES_PAGE = "SCHEDULE_MESSAGES_PAGE",
  USERS_PAGE = "USERS_PAGE",
  USER_CREATE_PAGE = "USER_CREATE_PAGE",
  USER_EDIT_PAGE = "USER_EDIT_PAGE",
  VISITS_CALENDAR_PAGE = "VISITS_CALENDAR_PAGE",
}

export enum DateRangeFilter {
  CREATED_AT = "CREATED_AT",
  INTERACTED = "INTERACTED",
  SERVICE_DATE = "SERVICE_DATE",
  UPDATED_AT = "UPDATED_AT",
}

export enum EmailDirectionEnum {
  RECEIVED = "RECEIVED",
  SENT = "SENT",
}

export enum EmailServiceEnum {
  SENDGRID = "SENDGRID",
  SES = "SES",
}

export enum EmailsByDayOrderByInput {
  date_ASC = "date_ASC",
  date_DESC = "date_DESC",
  direction_ASC = "direction_ASC",
  direction_DESC = "direction_DESC",
  from_ASC = "from_ASC",
  from_DESC = "from_DESC",
  service_ASC = "service_ASC",
  service_DESC = "service_DESC",
  subject_ASC = "subject_ASC",
  subject_DESC = "subject_DESC",
  success_ASC = "success_ASC",
  success_DESC = "success_DESC",
  to_ASC = "to_ASC",
  to_DESC = "to_DESC",
}

export enum ExternalChatChannelActionEventOperation {
  DELETE = "DELETE",
  INSERT = "INSERT",
  UPDATE = "UPDATE",
}

export enum ExternalChatChannelActionType {
  RECORDING = "RECORDING",
  TYPING = "TYPING",
}

export enum ExternalChatChannelAuthenticationError {
  AUTH_FAILURE = "AUTH_FAILURE",
  AUTH_OK_BUT_WRONG_ID_IN_CHANNEL = "AUTH_OK_BUT_WRONG_ID_IN_CHANNEL",
  QR_CODE_PENDING = "QR_CODE_PENDING",
}

export enum ExternalChatChannelInstanceType {
  DEFAULT = "DEFAULT",
  MEDIUM = "MEDIUM",
}

export enum ExternalChatChannelMetadataGrouping {
  ALL = "ALL",
  BY_CHANNEL = "BY_CHANNEL",
  BY_DATE = "BY_DATE",
  NONE = "NONE",
}

export enum ExternalChatChannelState {
  LOCAL_OFFLINE = "LOCAL_OFFLINE",
  MOBILE_OFFLINE = "MOBILE_OFFLINE",
  ONLINE = "ONLINE",
  QR_CODE_PENDING = "QR_CODE_PENDING",
  REMOTE_OFFLINE = "REMOTE_OFFLINE",
}

export enum ExternalChatChannelSyncStatus {
  NOT_SYNCING = "NOT_SYNCING",
  SYNCING_CONTACTS = "SYNCING_CONTACTS",
  SYNCING_MESSAGES = "SYNCING_MESSAGES",
}

export enum ExternalChatChannelType {
  CHAT_WIDGET = "CHAT_WIDGET",
  FACEBOOK_MESSENGER = "FACEBOOK_MESSENGER",
  INTERNAL_USER_CHAT = "INTERNAL_USER_CHAT",
  WHATSAPP = "WHATSAPP",
  WHATSAPP_GUPSHUP = "WHATSAPP_GUPSHUP",
  WHATSAPP_POSITUS = "WHATSAPP_POSITUS",
  WHATSAPP_Z_API = "WHATSAPP_Z_API",
  ZAP_MESSAGE = "ZAP_MESSAGE",
}

export enum ExternalChatChannelWebhookEventType {
  CHANNEL_STATE_UPDATE = "CHANNEL_STATE_UPDATE",
  MESSAGE_UPSERT = "MESSAGE_UPSERT",
}

export enum ExternalChatMessagePeer {
  CHANNEL = "CHANNEL",
  CLIENT = "CLIENT",
  GROUP = "GROUP",
  USER = "USER",
}

export enum ExternalChatMessageSubscriptionFiltersOperation {
  DELETE = "DELETE",
  INSERT = "INSERT",
  UPDATE = "UPDATE",
}

export enum ExternalChatMessageType {
  AUDIO = "AUDIO",
  CIPHERTEXT = "CIPHERTEXT",
  CONTACT_CARD = "CONTACT_CARD",
  CONTACT_CHANGED = "CONTACT_CHANGED",
  DOCUMENT = "DOCUMENT",
  IMAGE = "IMAGE",
  LOCATION = "LOCATION",
  REAL_ESTATE = "REAL_ESTATE",
  REVOKED = "REVOKED",
  START_NEW_CHAT = "START_NEW_CHAT",
  STICKER = "STICKER",
  TEXT = "TEXT",
  UNKNOWN = "UNKNOWN",
  VIDEO = "VIDEO",
  VOICE = "VOICE",
}

export enum MapLocationType {
  Point = "Point",
}

export enum MessageDirection {
  RECEIVED = "RECEIVED",
  SENT = "SENT",
}

export enum ObservationSendingMethod {
  EMAIL = "EMAIL",
  WHATSAPP_FIREFLY = "WHATSAPP_FIREFLY",
  WHATSAPP_LINK = "WHATSAPP_LINK",
}

export enum PeriodOfDay {
  AFTERNOON = "AFTERNOON",
  MORNING = "MORNING",
  NIGHT = "NIGHT",
  OTHER = "OTHER",
}

export enum PortalClientEmailButtonType {
  SCHEDULE_VISIT = "SCHEDULE_VISIT",
  SEARCH_OTHER_REAL_ESTATES = "SEARCH_OTHER_REAL_ESTATES",
  SPEAK_TO_CONSULTANT = "SPEAK_TO_CONSULTANT",
}

export enum RealEstateAgencyParentOrderByInput {
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
  url_ASC = "url_ASC",
  url_DESC = "url_DESC",
  useCustomExternalAgencyIdRedirects_ASC = "useCustomExternalAgencyIdRedirects_ASC",
  useCustomExternalAgencyIdRedirects_DESC = "useCustomExternalAgencyIdRedirects_DESC",
}

export enum RealEstateByAgencyDatabaseIdOrderBy {
  ABATIMENTO_ASC = "ABATIMENTO_ASC",
  ABATIMENTO_DESC = "ABATIMENTO_DESC",
  AGENCY_CREATED_AT_ASC = "AGENCY_CREATED_AT_ASC",
  AGENCY_CREATED_AT_DESC = "AGENCY_CREATED_AT_DESC",
  AGENCY_REAL_ESTATE_TYPE_ASC = "AGENCY_REAL_ESTATE_TYPE_ASC",
  AGENCY_REAL_ESTATE_TYPE_DESC = "AGENCY_REAL_ESTATE_TYPE_DESC",
  AGENT_EMAIL_ASC = "AGENT_EMAIL_ASC",
  AGENT_EMAIL_DESC = "AGENT_EMAIL_DESC",
  AGENT_NAME_ASC = "AGENT_NAME_ASC",
  AGENT_NAME_DESC = "AGENT_NAME_DESC",
  AGE_ASC = "AGE_ASC",
  AGE_DESC = "AGE_DESC",
  ALUGUEL_ASC = "ALUGUEL_ASC",
  ALUGUEL_DESC = "ALUGUEL_DESC",
  ALUGUEL_LIQUIDO_ASC = "ALUGUEL_LIQUIDO_ASC",
  ALUGUEL_LIQUIDO_DESC = "ALUGUEL_LIQUIDO_DESC",
  ALUGUEL_PLUS_CONDOMINIO_ASC = "ALUGUEL_PLUS_CONDOMINIO_ASC",
  ALUGUEL_PLUS_CONDOMINIO_DESC = "ALUGUEL_PLUS_CONDOMINIO_DESC",
  AVAILABLE_ASC = "AVAILABLE_ASC",
  AVAILABLE_DESC = "AVAILABLE_DESC",
  BATHROOMS_ASC = "BATHROOMS_ASC",
  BATHROOMS_DESC = "BATHROOMS_DESC",
  CAN_SWAP_ASC = "CAN_SWAP_ASC",
  CAN_SWAP_DESC = "CAN_SWAP_DESC",
  CITY_ASC = "CITY_ASC",
  CITY_DESC = "CITY_DESC",
  CONDOMINIO_ASC = "CONDOMINIO_ASC",
  CONDOMINIO_DESC = "CONDOMINIO_DESC",
  CONDOMINIO_FECHADO_ASC = "CONDOMINIO_FECHADO_ASC",
  CONDOMINIO_FECHADO_DESC = "CONDOMINIO_FECHADO_DESC",
  CONDOMINIO_NAME_ASC = "CONDOMINIO_NAME_ASC",
  CONDOMINIO_NAME_DESC = "CONDOMINIO_NAME_DESC",
  DEAL_TYPE_ASC = "DEAL_TYPE_ASC",
  DEAL_TYPE_DESC = "DEAL_TYPE_DESC",
  FACE_ASC = "FACE_ASC",
  FACE_DESC = "FACE_DESC",
  FGTS_ASC = "FGTS_ASC",
  FGTS_DESC = "FGTS_DESC",
  FINANCING_ASC = "FINANCING_ASC",
  FINANCING_DESC = "FINANCING_DESC",
  ID_IN_AGENCY_2_ASC = "ID_IN_AGENCY_2_ASC",
  ID_IN_AGENCY_2_DESC = "ID_IN_AGENCY_2_DESC",
  ID_IN_AGENCY_ASC = "ID_IN_AGENCY_ASC",
  ID_IN_AGENCY_DESC = "ID_IN_AGENCY_DESC",
  IMAGES_ASC = "IMAGES_ASC",
  IMAGES_DESC = "IMAGES_DESC",
  IPTU_ASC = "IPTU_ASC",
  IPTU_DESC = "IPTU_DESC",
  NEIGHBORHOOD_ASC = "NEIGHBORHOOD_ASC",
  NEIGHBORHOOD_DESC = "NEIGHBORHOOD_DESC",
  PARKING_ASC = "PARKING_ASC",
  PARKING_DESC = "PARKING_DESC",
  REAL_ESTATE_TYPE_ASC = "REAL_ESTATE_TYPE_ASC",
  REAL_ESTATE_TYPE_DESC = "REAL_ESTATE_TYPE_DESC",
  ROOMS_ASC = "ROOMS_ASC",
  ROOMS_DESC = "ROOMS_DESC",
  STATE_ASC = "STATE_ASC",
  STATE_DESC = "STATE_DESC",
  SUITES_ASC = "SUITES_ASC",
  SUITES_DESC = "SUITES_DESC",
  TERRAIN_AREA_ASC = "TERRAIN_AREA_ASC",
  TERRAIN_AREA_DESC = "TERRAIN_AREA_DESC",
  TOTAL_AREA_ASC = "TOTAL_AREA_ASC",
  TOTAL_AREA_DESC = "TOTAL_AREA_DESC",
  TOTAL_VALUE_ASC = "TOTAL_VALUE_ASC",
  TOTAL_VALUE_DESC = "TOTAL_VALUE_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
  URLS_ASC = "URLS_ASC",
  URLS_DESC = "URLS_DESC",
  USABLE_AREA_ASC = "USABLE_AREA_ASC",
  USABLE_AREA_DESC = "USABLE_AREA_DESC",
  USAGE_TYPE_ASC = "USAGE_TYPE_ASC",
  USAGE_TYPE_DESC = "USAGE_TYPE_DESC",
}

export enum RealEstateDataSource {
  APOLAR_API = "APOLAR_API",
  APRESENTA_ME = "APRESENTA_ME",
  ARBO = "ARBO",
  C2TI_XML = "C2TI_XML",
  CASA_SOFT_XML = "CASA_SOFT_XML",
  CSV_BASED = "CSV_BASED",
  IMOBZI = "IMOBZI",
  IMOVIEW = "IMOVIEW",
  INGAIA = "INGAIA",
  IWATA_XML = "IWATA_XML",
  JETIMOBI_JSON = "JETIMOBI_JSON",
  MB_IMOVEIS_WORDPRESS = "MB_IMOVEIS_WORDPRESS",
  MERCADO_LIVRE_XML = "MERCADO_LIVRE_XML",
  REDE_BEE = "REDE_BEE",
  REDE_IMOVEIS_XML = "REDE_IMOVEIS_XML",
  SI9 = "SI9",
  SIGAVI_JSON = "SIGAVI_JSON",
  SIMOB = "SIMOB",
  VISTA = "VISTA",
  VIVA_REAL_XML = "VIVA_REAL_XML",
  ZAP_IMOVEIS_XML = "ZAP_IMOVEIS_XML",
}

export enum RealEstateDealType {
  BUY = "BUY",
  RENT = "RENT",
}

export enum RealEstateExtraFeature {
  academia = "academia",
  aquecedor = "aquecedor",
  arCondicionado = "arCondicionado",
  banheiroSocial = "banheiroSocial",
  bicicletario = "bicicletario",
  brinquedoteca = "brinquedoteca",
  cameras = "cameras",
  churrasqueira = "churrasqueira",
  circuitoSeguranca = "circuitoSeguranca",
  closet = "closet",
  cozinha = "cozinha",
  elevador = "elevador",
  estacionamento = "estacionamento",
  hidromassagem = "hidromassagem",
  interfone = "interfone",
  jardim = "jardim",
  lareira = "lareira",
  lavanderia = "lavanderia",
  mobiliado = "mobiliado",
  piscina = "piscina",
  pisoCeramico = "pisoCeramico",
  playground = "playground",
  portaoEletronico = "portaoEletronico",
  portaria = "portaria",
  quadraEsportes = "quadraEsportes",
  sacada = "sacada",
  salaJantar = "salaJantar",
  salaoFestas = "salaoFestas",
  sauna = "sauna",
  spa = "spa",
}

export enum RealEstateIdType {
  INTERNAL_ID = "INTERNAL_ID",
  REFERENCE_CODE = "REFERENCE_CODE",
}

export enum RealEstateOrderBy {
  EXTRA_FEATURES_SCORE_DESC = "EXTRA_FEATURES_SCORE_DESC",
  LOCATION_SCORE_DESC = "LOCATION_SCORE_DESC",
  PRICE_ASC = "PRICE_ASC",
  PRICE_SCORE_DESC = "PRICE_SCORE_DESC",
  TOTAL_SCORE = "TOTAL_SCORE",
  TOTAL_SCORE_DESC = "TOTAL_SCORE_DESC",
}

/**
 * these types are from the apolar homepage
 */
export enum RealEstateType {
  apartamento = "apartamento",
  area = "area",
  barracao = "barracao",
  casa = "casa",
  casaDeCondominio = "casaDeCondominio",
  chacara = "chacara",
  cobertura = "cobertura",
  comercial = "comercial",
  fazenda = "fazenda",
  flat = "flat",
  galpao = "galpao",
  garagem = "garagem",
  jardim = "jardim",
  kitnet = "kitnet",
  loft = "loft",
  loja = "loja",
  predio = "predio",
  sala = "sala",
  sitio = "sitio",
  sobrado = "sobrado",
  studio = "studio",
  terreno = "terreno",
}

export enum RealEstateUsageType {
  BOTH = "BOTH",
  COMMERCIAL = "COMMERCIAL",
  CORPORATIVE = "CORPORATIVE",
  RESIDENTIAL = "RESIDENTIAL",
}

export enum RouletteStatus {
  CHECKED_IN = "CHECKED_IN",
  CHECKIN_DISABLED = "CHECKIN_DISABLED",
  DISABLED_AT_AGENCY = "DISABLED_AT_AGENCY",
  NOT_CHECKED_IN = "NOT_CHECKED_IN",
  NOT_IN_GROUP = "NOT_IN_GROUP",
  NO_AGENCY_FOR_USER = "NO_AGENCY_FOR_USER",
}

export enum UserRole {
  AGENT_CONSULTANT = "AGENT_CONSULTANT",
  AGENT_MANAGER = "AGENT_MANAGER",
  AGENT_SALESMAN = "AGENT_SALESMAN",
  FIREFLY_ADMIN = "FIREFLY_ADMIN",
  HARRY_ADMIN = "HARRY_ADMIN",
}

export enum UserRouletteEventType {
  AUTOMATIC_CHECKOUT = "AUTOMATIC_CHECKOUT",
  CHECKIN_FAILED = "CHECKIN_FAILED",
  CHECKIN_SUCCESS = "CHECKIN_SUCCESS",
  CHECKOUT_FAILED = "CHECKOUT_FAILED",
  CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS",
  CLIENTS_FILTERED_FOR_CHECKIN = "CLIENTS_FILTERED_FOR_CHECKIN",
  CLIENT_SHARED = "CLIENT_SHARED",
  CLIENT_SHARED_AFTER_CHECKIN = "CLIENT_SHARED_AFTER_CHECKIN",
}

export enum UsersByRoleOrderByInput {
  email_ASC = "email_ASC",
  email_DESC = "email_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
  phone_ASC = "phone_ASC",
  phone_DESC = "phone_DESC",
  role_ASC = "role_ASC",
  role_DESC = "role_DESC",
  telegramChatId_ASC = "telegramChatId_ASC",
  telegramChatId_DESC = "telegramChatId_DESC",
  telegramUser_ASC = "telegramUser_ASC",
  telegramUser_DESC = "telegramUser_DESC",
}

export interface AddressInput {
  street?: string | null;
  number?: string | null;
  complementary?: string | null;
  zip?: string | null;
  city?: string | null;
  state?: string | null;
  lat?: number | null;
  lon?: number | null;
  neighborhoods?: string[] | null;
  location?: MapLocationInput | null;
}

export interface AgencyActivitiesInput {
  id: string;
  page?: number | null;
  limit?: number | null;
  typeFilter?: (string | null)[] | null;
  messageFilter?: string | null;
  userFilter?: string | null;
}

export interface AgencyObservationInput {
  id?: string | null;
  text: string;
}

export interface AgencyRoulettesInput {
  ids?: (string | null)[] | null;
  page?: number | null;
  limit?: number | null;
  nameFilter?: string | null;
  activeFilter?: boolean | null;
  orderBy?: AgencyRoulettesOrderByInput | null;
}

export interface AgencyTemplateInput {
  id?: string | null;
  title?: string | null;
  text?: string | null;
  showOnlyInWidgetIds?: (string | null)[] | null;
}

export interface BrowserInfoInput {
  currentPageUrl?: string | null;
  userAgent?: string | null;
  screenWidth?: number | null;
  screenHeight?: number | null;
  windowWidth?: number | null;
  windowHeight?: number | null;
  ip?: string | null;
}

export interface ChatCustomerDataInput {
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  document?: string | null;
  ownerName?: string | null;
  ownerDocument?: string | null;
  salesmanName?: string | null;
  salesmanDocument?: string | null;
  salesmanCollectorName?: string | null;
  salesmanCollectorDocument?: string | null;
  managerName?: string | null;
  managerDocument?: string | null;
  avatarImageUrl?: string | null;
  civilState?: CivilState | null;
  birthDate?: any | null;
  occupation?: string | null;
  serviceData?: CustomerServiceDataInput | null;
  registrationChannel?: ChatCustomerRegistrationChannel | null;
  registrationUserId?: string | null;
  cep?: string | null;
  condominium?: string | null;
  unit?: string | null;
}

/**
 * this type comes from the prisma type
 */
export interface ChatFlowConfigInput {
  characterCommonGreeting1?: string | null;
  characterCommonGreeting2?: string | null;
  notUseCommonGreeting2?: boolean | null;
  characterGender?: ChatFlowCharacterGender | null;
  characterGreeting?: string | null;
  characterName?: string | null;
  disableRealEstateRelatedReactiveChat?: boolean | null;
  disableAutomaticDealTypeTagCreation?: boolean | null;
  disableAutomaticAdministrativeTagCreation?: boolean | null;
  disableAddressAtReactiveChat?: boolean | null;
  disableRealEstateSearch?: boolean | null;
  disableVisitSchedule?: boolean | null;
  emailRequired?: boolean | null;
  customWhatsappInitialMessage?: string | null;
  enableNumericalReplyOptions?: boolean | null;
  enableRegions?: boolean | null;
  newChatOnPageChange?: boolean | null;
  ommitPriceSuggestionsAtSearch?: boolean | null;
  personalDataBeforeMessageAtSpeakWithSalesman?: boolean | null;
  personalDataBeforeRealEstateSearch?: boolean | null;
  useReactiveChatOnly?: boolean | null;
  saluteMessages?: string[] | null;
  opinionAskMessage?: string | null;
  sendToHumanAtSpeakWithSalesman?: boolean | null;
  whatsappUrlAtAskVisitFlow?: boolean | null;
  showCovidMessage?: boolean | null;
  redirectToWoliverUrl?: boolean | null;
  woliverSubdomain?: string | null;
  askForBotEvaluation?: boolean | null;
  askForAttendantEvaluation?: boolean | null;
  disableRoomsMin?: boolean | null;
  customEndingMessage?: string | null;
}

export interface ChatFlowInput {
  config: ChatFlowConfigInput;
}

export interface ChatSubFlowParamsInput {
  evaluatingAttendant?: boolean | null;
}

export interface ChatWidgetScheduleInput {
  weekDay: ChatWidgetScheduleWeekday;
  startTime: any;
  endTime: any;
}

export interface ChatWidgetThemeInput {
  alwaysAutoOpen?: boolean | null;
  autoOpenInRealEstatePagesOnly?: boolean | null;
  autoOpenProbability?: number | null;
  autoOpenTimeMsMax?: number | null;
  autoOpenTimeMsMin?: number | null;
  enableResetChatButton?: boolean | null;
  greetingBubbleMessage?: string | null;
  headerImage?: string | null;
  headerTitle?: string | null;
  hideImageLinks?: boolean | null;
  hideWidget?: boolean | null;
  hideWidgetInUrls?: string[] | null;
  isLinkButton?: boolean | null;
  linkButtonUrl?: string | null;
  linkButtonUrlWithRealEstate?: string | null;
  showWidgetInUrls?: string[] | null;
  launcherBottomSpacing?: string | null;
  isLauncherPositionTop?: boolean | null;
  launcherRightSpacing?: string | null;
  isLauncherPositionLeft?: boolean | null;
  showGreetingBubble?: boolean | null;
  startFullScreen?: boolean | null;
  enableRegions?: boolean | null;
  disableFireflyLogo?: boolean | null;
  disableRealEstateCardAddress?: boolean | null;
  themeStyles?: ChatWidgetThemeStylesInput | null;
}

export interface ChatWidgetThemeStylesInput {
  baseZIndex?: string | null;
  launcher: ThemeLauncherInput;
  chatWindow: ThemeChatWindowInput;
  header: ThemeHeaderInput;
  messageList: ThemeMessageListInput;
  userInput?: UserInputInput | null;
  trigger?: ThemeTriggerInput | null;
}

export interface ClientActivityInput {
  date: any;
  type: ClientActivityType;
  success: boolean;
  message?: string | null;
  destinationUserIds?: string[] | null;
}

export interface ClientFunnelStepsDataInput {
  clientId: string;
  funnelStepId?: string | null;
  funnelStepPosition?: number | null;
}

export interface CustomAuxTagTextInput {
  id?: string | null;
  text: string;
  auxTag: ChatClientTagType;
}

export interface CustomChatStepInput {
  id?: string | null;
  subFlow: ChatSubFlowName;
  agencyChildId: string;
  messageIn: string;
  messageOut?: string | null;
  stopBot?: boolean | null;
  flowToRedirect?: ChatSubFlowName | null;
}

export interface CustomReactiveChatQuestionInput {
  id?: string | null;
  fullQuestion?: string | null;
  keywords: string[];
  answer: string;
  mustBeAQuestion?: boolean | null;
  ommitOtherReactiveResponses?: boolean | null;
  isButtonOnly?: boolean | null;
  flowToRedirect?: ChatSubFlowName | null;
  stepsToShow: CrcStepShowEnum[];
  stopBot?: boolean | null;
  position?: number | null;
  showOnlyInWidgetIds?: string[] | null;
  tagsToAddToClient?: ChatClientTagType[] | null;
  realEstateRentToAddToClient?: string | null;
  realEstateBuyToAddToClient?: string | null;
}

export interface CustomerServiceDataInput {
  date?: any | null;
  origin?: CustomerOrigin | null;
  preferredChannels?: CustomerPreferredChannel[] | null;
  preferredRealEstate?: string | null;
  preferredRealEstateValue?: number | null;
  agency?: string | null;
  agent?: string | null;
  funnelStepId?: string | null;
  funnelStepPosition?: number | null;
}

export interface DepartmentInput {
  id?: string | null;
  name: string;
  tags: ChatClientTagType[];
}

export interface EmailToNotifyInput {
  email: string;
  notificationSettings: NotificationSettingsInput;
}

export interface EvaluationsInput {
  startDate: any;
  endDate: any;
  widgetIds: string[];
  first?: number | null;
  skip?: number | null;
}

export interface ExternalChatChannelActionInput {
  date: any;
  externalChatChannelId: string;
  from: ExternalChatMessagePeer;
  fromId: string;
  status: boolean;
  to: ExternalChatMessagePeer;
  toId: string;
  type: ExternalChatChannelActionType;
}

export interface ExternalChatChannelInput {
  name?: string | null;
  disabled?: boolean | null;
  botEnabled?: boolean | null;
  botReactivationTimeSeconds?: number | null;
  disableBotAfterFirstAnswer?: boolean | null;
  disableGroups?: boolean | null;
  enableWhatsappSessionSave?: boolean | null;
  idInChannel?: string | null;
  shouldSendUserName?: boolean | null;
  markAsReadAfterReply?: boolean | null;
  chatsSyncLimit?: number | null;
  messagesSyncLimit?: number | null;
  qrCode?: string | null;
  key?: string | null;
  token?: string | null;
  authenticationError?: ExternalChatChannelAuthenticationError | null;
  authenticationidInChannel?: string | null;
  state?: ExternalChatChannelState | null;
  isMobileBatteryPlugged?: boolean | null;
  localServerHeartbeatDate?: any | null;
  mobileBatteryPercent?: number | null;
  statusDescription?: string | null;
  statusInChannel?: string | null;
  type: ExternalChatChannelType;
  instanceType?: ExternalChatChannelInstanceType | null;
}

export interface ExternalChatChannelWebhookInput {
  disabled?: boolean | null;
  eventTypes: ExternalChatChannelWebhookEventType[];
  httpEndpoint: string;
  name?: string | null;
  verifyToken: string;
}

export interface ExternalChatMessageContactAddressInput {
  fullAddress?: string | null;
  city?: string | null;
  country?: string | null;
  countryCode?: string | null;
  state?: string | null;
  street?: string | null;
  type?: string | null;
  zip?: string | null;
}

export interface ExternalChatMessageContactEmailInput {
  email: string;
  type?: string | null;
}

export interface ExternalChatMessageContactInput {
  addresses?: ExternalChatMessageContactAddressInput[] | null;
  emails?: ExternalChatMessageContactEmailInput[] | null;
  name?: string | null;
  phones?: ExternalChatMessageContactPhoneInput[] | null;
}

export interface ExternalChatMessageContactPhoneInput {
  phone: string;
  type?: string | null;
}

export interface ExternalChatMessageFilters {
  afterDate?: any | null;
  beforeDate?: any | null;
  clientIds?: string[] | null;
  clientSecrets?: string[] | null;
  excludeMessageIds?: string[] | null;
  externalChatChannelIds: string[];
  last?: number | null;
  ignoreMaxMessagesLimit?: boolean | null;
  isHarryChat?: boolean | null;
}

export interface ExternalChatMessageLocationInput {
  description?: string | null;
  lat: number;
  lon: number;
}

export interface ExternalChatMessageSubscriptionFiltersInput {
  clientIds?: string[] | null;
  clientSecrets?: string[] | null;
  externalChatChannelIds?: string[] | null;
  from?: ExternalChatMessagePeer[] | null;
  to?: ExternalChatMessagePeer[] | null;
  operations?: ExternalChatMessageSubscriptionFiltersOperation[] | null;
  markedForDeletionMessagesOnly?: boolean | null;
}

export interface FunnelStepInput {
  id?: string | null;
  name: string;
  position: number;
  color?: string | null;
  isFinal?: boolean | null;
  isUnShareDestiny?: boolean | null;
  daysToNotify?: number | null;
  description?: string | null;
  idInCrm?: string | null;
}

/**
 * GeoJSON type. Example: 
 *  location: {
 *    type: "Point",
 *    coordinates: [-73.856077, 40.848447]
 *  }
 */
export interface MapLocationInput {
  type?: MapLocationType | null;
  coordinates: number[];
}

export interface MongoDbChangeStreamResumeTokenInput {
  _data: string;
}

export interface NeighborhoodInput {
  _id?: string | null;
  aliases?: string[] | null;
  city?: string | null;
  country?: string | null;
  location?: MapLocationInput | null;
  name?: string | null;
  region?: string | null;
  state?: string | null;
}

export interface NotificationSettingsInput {
  administrativeBuy: boolean;
  administrativeRent: boolean;
  administrativeUndefinedDealType: boolean;
  administrativeBadEvaluation: boolean;
  angariationBuy: boolean;
  angariationRent: boolean;
  angariationUndefinedDealType: boolean;
  angariationBadEvaluation: boolean;
  financialBuy: boolean;
  financialRent: boolean;
  financialUndefinedDealType: boolean;
  financialBadEvaluation: boolean;
  leadsBuy: boolean;
  leadsRent: boolean;
  leadsUndefinedDealType: boolean;
  leadsBadEvaluation: boolean;
  emailsToNotifyError?: boolean | null;
  notifyCrmError: boolean;
  ackThreshold: boolean;
  clientChangedAgency: boolean;
  qrCode: boolean;
}

export interface RealEstateAgencyChildCreateInput {
  address?: AddressInput | null;
  crmAgencyCode?: string | null;
  crmEnabled?: boolean | null;
  crmSendMethod?: CrmSendMethod | null;
  crmSubdomain?: string | null;
  crmToken?: string | null;
  crmType?: CrmType | null;
  crmUsername?: string | null;
  crmPassword?: string | null;
  crmChannelName?: string | null;
  crmChannelName2?: string | null;
  crmSendLastConversation?: boolean | null;
  crmDefaultBuyRealEstate?: string | null;
  crmDefaultRentRealEstate?: string | null;
  crmDefaultEnterpriseRealEstate?: string | null;
  crmDefaultRealEstateCode?: string | null;
  crmQueueName?: string | null;
  crmSetAsOpportunity?: boolean | null;
  enableHarryLogin?: boolean | null;
  resendToCrm?: boolean | null;
  resendEmail?: boolean | null;
  crmDisablesAngariation?: boolean | null;
  crmDisablesAdministrative?: boolean | null;
  crmDisablesFinancial?: boolean | null;
  crmDisablesUndefinedDealType?: boolean | null;
  crmDisabledDealType?: RealEstateDealType | null;
  crmDisablesPartnership?: boolean | null;
  billetType?: BilletType | null;
  billetToken1?: string | null;
  billetToken2?: string | null;
  billetUsername?: string | null;
  billetPassword?: string | null;
  billetSubdomain?: string | null;
  defaultDDD?: string | null;
  disabledDealType?: RealEstateDealType | null;
  idInAgencyParent?: string | null;
  name: string;
  openClientDetailsPage?: boolean | null;
  url?: string | null;
  whatsappNumber?: string | null;
  enableSendAnswerToPortalClient?: boolean | null;
  portalClientEmailButtons?: PortalClientEmailButtonType[] | null;
  isRouletteActive?: boolean | null;
  distributeAllLeadsToFirstSalesman?: boolean | null;
  maxLeadsPerSalesman?: number | null;
  isManagerBotActive?: boolean | null;
  managerBotInterval?: number | null;
  updateCrmFunnelStep?: boolean | null;
  firstFunnelStepDefault?: boolean | null;
  defaultFunnelStep?: string | null;
  enableSendObservationToClient?: boolean | null;
  hideOtherSalesmanObservations?: boolean | null;
  cnpj?: string | null;
  contactPersonResponsible?: string | null;
  clientsImporterType?: ClientsImporterType | null;
  clientsImporterToken?: string | null;
  clientsImporterSubdomain?: string | null;
  clientsImporterChatWidgetId?: string | null;
}

export interface RealEstateAgencyChildUpdateInput {
  address?: AddressInput | null;
  crmAgencyCode?: string | null;
  crmEnabled?: boolean | null;
  crmSendMethod?: CrmSendMethod | null;
  crmSubdomain?: string | null;
  crmToken?: string | null;
  crmType?: CrmType | null;
  crmUsername?: string | null;
  crmPassword?: string | null;
  crmChannelName?: string | null;
  crmChannelName2?: string | null;
  crmSendLastConversation?: boolean | null;
  crmDefaultBuyRealEstate?: string | null;
  crmDefaultRentRealEstate?: string | null;
  crmDefaultEnterpriseRealEstate?: string | null;
  crmDefaultRealEstateCode?: string | null;
  crmQueueName?: string | null;
  crmSetAsOpportunity?: boolean | null;
  enableHarryLogin?: boolean | null;
  resendToCrm?: boolean | null;
  resendEmail?: boolean | null;
  crmDisablesAngariation?: boolean | null;
  crmDisablesAdministrative?: boolean | null;
  crmDisablesFinancial?: boolean | null;
  crmDisablesUndefinedDealType?: boolean | null;
  crmDisabledDealType?: RealEstateDealType | null;
  crmDisablesPartnership?: boolean | null;
  billetType?: BilletType | null;
  billetToken1?: string | null;
  billetToken2?: string | null;
  billetUsername?: string | null;
  billetPassword?: string | null;
  billetSubdomain?: string | null;
  defaultDDD?: string | null;
  disabledDealType?: RealEstateDealType | null;
  idInAgencyParent?: string | null;
  name?: string | null;
  openClientDetailsPage?: boolean | null;
  url?: string | null;
  whatsappNumber?: string | null;
  enableSendAnswerToPortalClient?: boolean | null;
  portalClientEmailButtons?: PortalClientEmailButtonType[] | null;
  isRouletteActive?: boolean | null;
  distributeAllLeadsToFirstSalesman?: boolean | null;
  maxLeadsPerSalesman?: number | null;
  isManagerBotActive?: boolean | null;
  managerBotInterval?: number | null;
  updateCrmFunnelStep?: boolean | null;
  firstFunnelStepDefault?: boolean | null;
  defaultFunnelStep?: string | null;
  enableSendObservationToClient?: boolean | null;
  hideOtherSalesmanObservations?: boolean | null;
  cnpj?: string | null;
  contactPersonResponsible?: string | null;
  clientsImporterType?: ClientsImporterType | null;
  clientsImporterToken?: string | null;
  clientsImporterSubdomain?: string | null;
  clientsImporterChatWidgetId?: string | null;
}

export interface RealEstateAgencyDatabaseCreateInput {
  name: string;
  realEstatesImportConfig?: RealEstatesImportConfigInput | null;
  realEstatesUrlRegexpConfig?: RealEstatesUrlRegexpConfigInput | null;
}

export interface RealEstateAgencyDatabaseUpdateInput {
  name?: string | null;
  realEstatesImportConfig?: RealEstatesImportConfigInput | null;
  realEstatesUrlRegexpConfig?: RealEstatesUrlRegexpConfigInput | null;
}

export interface RealEstateAgencyParentsInput {
  ids?: string[] | null;
  page?: number | null;
  limit?: number | null;
  orderBy?: RealEstateAgencyParentOrderByInput | null;
  nameFilter?: string | null;
  crmTypeFilter?: (CrmType | null)[] | null;
  disabledDealTypeFilter?: (RealEstateDealType | null)[] | null;
  selectedAgencyIds?: (string | null)[] | null;
}

export interface RealEstateSearchFilters {
  agencyIds?: string[] | null;
  dealTypes: RealEstateDealType[];
  price?: number | null;
  address?: AddressInput | null;
  usageType?: RealEstateUsageType | null;
  realEstateTypes?: RealEstateType[] | null;
  roomsMin?: number | null;
  roomsMax?: number | null;
  areaMin?: number | null;
  areaMax?: number | null;
  parkingMin?: number | null;
  parkingMax?: number | null;
  extraFeatures?: RealEstateExtraFeature[] | null;
  excludeIds?: string[] | null;
  idInAgency?: string | null;
}

export interface RealEstatesImportConfigInput {
  dataSource?: RealEstateDataSource | null;
  url?: string | null;
  encoding?: string | null;
  key?: string | null;
  agencyCodes?: string[] | null;
  state?: string | null;
  removeNonNumbersFromIdInAgency?: boolean | null;
  updateDisabled?: boolean | null;
  username?: string | null;
  password?: string | null;
  allowsNoValueRealEstates?: boolean | null;
  finality?: string | null;
  enterprise?: boolean | null;
}

export interface RealEstatesUrlRegexpConfigInput {
  idRegexpStr?: string | null;
  idTypeInUrl?: RealEstateIdType | null;
  testUrl?: string | null;
  testId?: string | null;
}

export interface RouletteEventsInput {
  widgetIds: string[];
  createdAtStart: any;
  createdAtEnd: any;
  userId?: string | null;
  limit?: number | null;
  page?: number | null;
  userNameFilter?: string | null;
  typeFilter?: (UserRouletteEventType | null)[] | null;
  isDashboardEventFilter?: boolean | null;
  messageFilter?: string | null;
  salesGroupsNameFilter?: (string | null)[] | null;
}

export interface RoulettePeriodInput {
  id: string;
  period: PeriodOfDay;
  weekDay: number;
  start: number;
  end: number;
}

export interface SalesGroupInput {
  id?: string | null;
  name: string;
  agencyChildId?: string | null;
  activePeriods: PeriodOfDay[];
  activeWeekdays: number[];
  activeMonthDays: number[];
  activeTags: ChatClientTagType[];
  forbiddenTags: ChatClientTagType[];
  activeDDDs: string[];
  forbiddenDDDs: string[];
  disabledDealType?: RealEstateDealType | null;
  isDailyCheckin?: boolean | null;
  isActive: boolean;
  isPropertyRaiserPriorital?: boolean | null;
  defaultPropertyRaiserEmail?: string | null;
  propertyRaiserMustBeInRoulette?: boolean | null;
  leadsCount?: number | null;
  salespeopleEmails: string[];
  allowedEmails: string[];
}

export interface ScheduleMessageInput {
  id?: string | null;
  externalChatChannelId: string;
  message: string;
  sendAt: any;
  tagsFilter?: (ChatClientTagType | null)[] | null;
  funnelStepIdFilter?: (string | null)[] | null;
}

export interface ThemeAvatarInput {
  icon: string;
  backgroundColor?: string | null;
  backgroundSize?: string | null;
  backgroundOrigin?: string | null;
  minWidth: string;
  minHeight: string;
  padding?: string | null;
  borderRadius?: string | null;
  boxSizing?: string | null;
}

export interface ThemeButtonInput {
  hoverBackground?: string | null;
}

export interface ThemeChatWindowInput {
  logoFontImportant?: boolean | null;
}

export interface ThemeGreetingBubbleInput {
  color?: string | null;
  backgroundColor: string;
  padding?: string | null;
  borderRadius?: string | null;
}

export interface ThemeHeaderInput {
  backgroundPrimary: string;
  backgroundSecondary?: string | null;
  backgroundImage?: string | null;
  borderBottom?: string | null;
  color?: string | null;
  minHeight: string;
  lineHeight: string;
  button: ThemeButtonInput;
  iconColor?: string | null;
}

export interface ThemeHoverInput {
  background: string;
  color?: string | null;
}

export interface ThemeLaucherOpenedInput {
  icon: string;
  backgroundColor: string;
}

export interface ThemeLauncherClosedInput {
  icon: string;
  backgroundColor: string;
  backgroundSize?: string | null;
  backgroundOrigin?: string | null;
  boxSizing?: string | null;
  padding?: string | null;
}

export interface ThemeLauncherInput {
  size?: string | null;
  opened: ThemeLaucherOpenedInput;
  closed: ThemeLauncherClosedInput;
  greetingBubble: ThemeGreetingBubbleInput;
  imgMargin: string;
  linkColor?: string | null;
  linkVisitedColor?: string | null;
}

export interface ThemeMessageBubbleInput {
  sent: ThemeMessageBubbleSentInput;
  received: ThemeMessageBubbleReceivedInput;
}

export interface ThemeMessageBubbleReceivedInput {
  color?: string | null;
  backgroundColor?: string | null;
  border?: string | null;
  borderColor?: string | null;
  borderRadius?: string | null;
}

export interface ThemeMessageBubbleSentInput {
  color?: string | null;
  backgroundColor?: string | null;
  border?: string | null;
  borderColor?: string | null;
  borderRadius?: string | null;
}

export interface ThemeMessageCounterInput {
  color?: string | null;
  backgroundColor?: string | null;
  top?: string | null;
  right?: string | null;
  width?: string | null;
  height?: string | null;
}

export interface ThemeMessageListInput {
  avatar: ThemeAvatarInput;
  scrollbarThumb: ThemeScrollbarThumbInput;
  replyOptionButton: ThemeReplyOptionButtonInput;
  textMessage: ThemeTextMessageInput;
  messageBubble: ThemeMessageBubbleInput;
  messageCounter?: ThemeMessageCounterInput | null;
  backgroundImage?: string | null;
  backgroundColor?: string | null;
}

export interface ThemeReplyOptionButtonInput {
  color: string;
  backgroundColor: string;
  border: string;
  borderColor: string;
  borderRadius: string;
  boxShadow?: string | null;
  padding?: string | null;
  hover: ThemeHoverInput;
}

export interface ThemeScrollbarThumbInput {
  backgroundColor: string;
}

export interface ThemeTextMessageInput {
  sent: ThemeTextMessageSentInput;
  received: ThemeTextMessageReceivedInput;
}

export interface ThemeTextMessageReceivedInput {
  color?: string | null;
  introColor?: string | null;
}

export interface ThemeTextMessageSentInput {
  color?: string | null;
}

export interface ThemeTriggerInput {
  newMessageKeyframes?: string | null;
  closedChatKeyframes?: string | null;
}

export interface UpdateChatWidgetDataInput {
  theme?: ChatWidgetThemeInput | null;
  chatFlow?: ChatFlowInput | null;
  name?: string | null;
  channel?: ChatWidgetChannel | null;
  disableCrm?: boolean | null;
  disableRoulette?: boolean | null;
  disabledDealTypes?: RealEstateDealType[] | null;
  disableResendEmail?: boolean | null;
  disableNotifications?: boolean | null;
  disableCloneCreation?: boolean | null;
  sendBadEvaluationNotification?: boolean | null;
  analyticsId?: string | null;
  analyticsType?: ChatWidgetAnalyticsType | null;
}

export interface UserInputInput {
  color?: string | null;
  border?: string | null;
  borderColor?: string | null;
  backgroundColor?: string | null;
  borderRadius?: string | null;
  iconColor?: string | null;
}

export interface UsersByRoleInput {
  roles: UserRole[];
  first?: number | null;
  skip?: number | null;
  orderBy?: UsersByRoleOrderByInput | null;
  nameFilter?: string | null;
  emailFilter?: string | null;
  phoneFilter?: string | null;
  agencyFilter?: string | null;
  telegramUserFilter?: string | null;
  telegramChatIdFilter?: string | null;
  widgetIds?: (string | null)[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
